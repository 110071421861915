if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js', {
        scope: '/'
      });
}
var loadDeferredStyles = function() {
    var styles = document.getElementsByClassName("css-defer");
    for (var index = 0; index < styles.length; index++) {
        var styleNode = styles[index];
        var replacement = document.createElement("div");
        replacement.innerHTML = styleNode.textContent;
        document.body.appendChild(replacement)
        styleNode.parentElement.removeChild(styleNode);
    }
}
var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
          window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
      if (raf) raf(function() { window.setTimeout(loadDeferredStyles, 0); });
      else window.addEventListener('load', loadDeferredStyles);
(function ($) {
    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        viewportTop -= 200;
        viewportBottom += 200;
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    $(window).on('resize scroll', function () {
        checkLoad()
    })
    function checkLoad() {
        $('.rn-sp').not('.loaded').each(function () {
            if ($(this).isInViewport()) {
                loadRNsp(this);
            }
        })
    }
    function loadRNsp(elem) {
        $this = $(elem);
        $this.addClass('loaded');
        var type = $this.data('type');
        var id = $this.attr('id')
        var datas = {
            action: 'get_sponsor',
            type: type
        };
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'GET',
            data: datas,
            success: function (data) {
                $('#' + id).html(data);
            }
        })
    }
    checkLoad();
    var myLazyLoad = new LazyLoad();
    $(document).on('mousedown', 'a', function () { if (("http:" === this.protocol || "https:" === this.protocol) && -1 === this.hostname.indexOf(document.location.hostname)) { var t = this.getAttribute("data-gaprefix"); t = null != t ? t : ""; var e = this.href, o = this.getAttribute("target"); return o || this.setAttribute("target", "_blank"), ga("send", "event", "outbound", "click", t + e, { transport: "beacon", hitCallback: function () { } }), !1 } });
})(jQuery);